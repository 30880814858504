/* https://serverless-stack.com/chapters/setup-custom-fonts.html */
body {
    margin: 0;
    padding: 0;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: #333;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "PT Serif", serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* https://serverless-stack.com/chapters/setup-bootstrap.html */
select.form-control,
textarea.form-control,
input.form-control {
    font-size: 16px;
}
input[type="file"] {
    width: 100%;
}

.tox-notifications-container {
    display: none !important;
}